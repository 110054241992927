// Utility function to create slugs from text
export function slugify(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }
  
  // Utility function to clean titles
  export function cleanTitle(title) {
    return title.replace(/\?$/, ''); // Removes a trailing '?' if it exists
  }
  