import { useParams } from "react-router-dom";

import postData from "../Posts/data";
import ContainerUI from "../../shared/ContainerUI";
import { slugify, cleanTitle } from "../../helpers/slugUtils"; // Import the utilities


const Posts = () => {
  const { postSlug } = useParams();
  console.log("postSlug from useParams:", postSlug);

  const post = postData.find(post => cleanTitle(slugify(post.title)) === postSlug);
  console.log("postData array:", postData);
  console.log("Found post:", post);

  if (!post) {
    return <div>Post not found</div>;
  }

return (
    <div className="px-[20px] py-[30px] sm:px-[50px] sm:py-[40px] lg:px-[80px] lg:py-[100px]">
      <div className="flex flex-col bg-[#F0F1F9] p-[15px] sm:p-[30px] rounded-[10px] lg:rounded-[20px]">
        <ContainerUI
          isExpandable={true}
          imgUrl={`/post_${post.id}.png`}
          title={post.title}
          description={post.description}
        />
        <div className="link flex flex-col gap-[5px] sm:gap-[15px] lg:gap-[30px] py-[30px] pb-[12px] lg:pb-0">
          {post.info.map(item => (
            <div key={item.id} className="flex flex-col gap-[5px] sm:gap-[15px] lg:gap-[30px] text-[#3B457B]">
              <div className="text-sm sm:text-xl font-semibold sm:leading-5">
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
              <div className="flex flex-col gap-[5px] sm:gap-[15px] lg:gap-[30px] text-[10px] sm:text-base font-normal">
                {item.description.map(i => (
                  <div key={i.id} className="sm:leading-5">
                    <div dangerouslySetInnerHTML={{ __html: i.part }} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Posts;