import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";

export const loginUser = createAsyncThunk(
  'userLogin/userLogin',
  async (formData) => {

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    const data = await response.json();

    return data;
  }
)

export const getLoggedInUser = createAsyncThunk(
  'user/getCurrent',
  async (formData) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/me`, {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    const data = await response.json();

    return data;
  }
)

export const getUserRefresh = createAsyncThunk(
  'getUser/refresh',
  async (formData) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/updateuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    const data = await response.json();

    return data;
  }
)

export const loginUserForm = createSlice({
  name: 'userLogin',
  initialState: null,
  reducers: {
    userLogin: (state, action) => {
      state = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if(action.payload?.user){
        state=action.payload?.user
      }
      if(action.payload?.error){
        state=action.payload
      }      
      return state;
    }).addCase(getLoggedInUser.fulfilled, (state, action) => {
      state = action.payload.user;

      return state
    }).addCase(getLoggedInUser.rejected, (state) => {
      state = null;

      return state;
    }).addCase(getUserRefresh.fulfilled, (state, action) => {
      state = action.payload.user;

      return state;
    })
  }
})

export const { userLogin } = loginUserForm.actions;

export default loginUserForm.reducer;

