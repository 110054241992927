import { createSlice } from "@reduxjs/toolkit";
import { current } from '@reduxjs/toolkit'
const initialState = {
  text: "",
  allOptions: [
    {
      id: 0,
      options: [
        {
          id: 0,
          value: "Humanize",
          field: "humanize",
          checked: false
        },
        {
          id: 1,
          value: "Improve Writing Quality",
          field: "improveWritingQuality",
          checked: false
        },
      ]
    },
    {
      id: 1,
      options: [
        {
          id: 0,
          value: "High School Level",
          field: "highSchoolLevel",
          checked: false
        },
        {
          id: 1,
          value: "Undergraduate Level",
          field: "undergraduateLevel",
          checked: false
        },
        {
          id: 2,
          value: "PhD Level",
          field: "phdLevel",
          checked: false
        },
      ]
    },
    {
      id: 2,
      options: [
        {
          id: 0,
          value: "Increase Word Count",
          field: "increaseWordCount",
          checked: false
        },
        {
          id: 1,
          value: "Decrease Word Count",
          field: "decreaseWordCount",
          checked: false
        },
      ]
    }
  ]
}

export const optionsData = createSlice({
  name: 'optionsData',
  initialState,
  reducers: {
    setOptionsData: (state, action) => {
      return { ...state, ...action.payload }
    },
    setOptionsDataChecked: (state, action) => {
      const [parentId, id, checked] = action.payload;
      const updatedOptionsData = current(state.allOptions)
      const copyOptions = structuredClone(updatedOptionsData)
      if (copyOptions && copyOptions[parentId].options && copyOptions[parentId].options[+id]) {
        copyOptions[parentId].options?.map(e => e.checked = false);
        copyOptions[parentId].options[+id].checked = checked;
      }
      return { ...state, allOptions: copyOptions};
    }
  },
})

export const { setOptionsData, setOptionsDataChecked } = optionsData.actions;

export default optionsData.reducer;
