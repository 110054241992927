const CustomOptions = ({ id, options, withObjectOptions, handleChange }) => {
  return (
    <div >
      {
        withObjectOptions ?
          options.map((option) => (
            <div key={option.field} className=" flex items-center gap-2.5">
              <input type="radio" id={`checkbox_${option.id}_${id}`} checked={option.checked} name={`checkbox_${id}`} value={option.value} onChange={(e)=>handleChange(id,e)} />
              <label htmlFor={`checkbox_${option.id}_${id}`}> {option.value}</label>
            </div>
          )) :
          options.map((option) => (
            <div key={option}  className=" flex items-center gap-2.5">
             <input type="radio" id={`checkbox_${option.id}_${id}`} checked={option.checked} name={`checkbox_${id}`} value={option.value} onChange={(e)=>handleChange(id,e)} />
              <label htmlFor={`checkbox_${option.id}_${id}`}> {option.value}</label>
            </div>
          ))
      }
    </div>)
}

export default CustomOptions