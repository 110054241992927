import { Fragment, useRef, useState } from "react";
import PriceUI from "../../shared/PriceUI";
import { ToastContainer, toast } from 'react-toastify';
import priceInfo from "./data.json"
const Pricing = () => {
  const [isCheckPaymentRules, setIsCheckPaymentRules] = useState(false);
  const isLoggedIn = useRef(true);

  const paymentErrorMessageHandler = () => {
    if (isLoggedIn.current) {
      toast.error("Something wrong happened, please try again");
    }
  }
  const cancelMessageHandler = () => toast.info("Operation cancelled by User request");
  const openInfoModal = () => {
    toast.info("You must agree to the terms and conditions");
  }
  const onPaymentSuccess = () => {
    toast.success("The payment was successful!");
  }
  const sessionExpired = () => {
    isLoggedIn.current = false;
    toast.error("Your login session has expired, please login again!")
  };

  return (
    <>
      <section className="flex flex-col px-5 py-[30px] sm:px-[70px] gap-[30px]">
        <div className="flex flex-col items-center gap-[30px] text-center">
          <h1 className="text-[#F0F1F9] text-xl sm:text-[28px] lg:text-[32px] font-medium ">
            Choose the Package that is Right for You!
          </h1>
          <p className="text-[#FFF] text-sm font-medium">
            Premium words offers a more smooth, naturally written, and more human version of the essay, with a higher degree of undetectability. Premium words can also be used to humanize essays in other languages.
          </p>
        </div>
        <div className="flex flex-col items-center gap-[30px]">
          <p className="text-base text-[#F0F1F9] font-medium">
            One-Time Purchase!
          </p>
          <div className="flex flex-col sm:flex-row gap-[30px] lg:gap-[60px]">
            {
              priceInfo.map((blockPrice) => (
                <Fragment key={blockPrice.id}>
                  <PriceUI
                    openInfoModal={openInfoModal}
                    isCheckPaymentRules={isCheckPaymentRules}
                    errorMessageHandler={paymentErrorMessageHandler}
                    cancelMessageHandler={cancelMessageHandler}
                    sessionExpired={sessionExpired}
                    onPaymentSuccess={onPaymentSuccess}
                    price={blockPrice.price}
                    about={blockPrice.about}
                    title={blockPrice.title}
                    packageType={blockPrice.packageType}
                    contentArr={blockPrice.contentArr}
                  />
                </Fragment>
              ))
            }
          </div>

          <div className="flex py-[10px]  w-[250px] sm:w-full  items-baseline gap-2.5">
            <input
              id="default-checkbox"
              type="checkbox"
              defaultChecked={isCheckPaymentRules}
              className="cursor-pointer"
              onChange={(e) => { setIsCheckPaymentRules(e.target.checked) }}
            />
            <label
              htmlFor="default-checkbox"
              className="flex flex-col text-center sm:text-start text-[#FFF] text-base	font-light cursor-pointer">
              I agree to use this service in an ethical manner and assume full responsibility for its usage.
            </label>
          </div>
        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Pricing;