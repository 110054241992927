export const setStorageItemHandler = (key) => () => sessionStorage.setItem(key, key);

export const calculateWordCount = (text) => {
  if(text){
    const arr = text.split(' ');
    return arr.filter(word => word !== "").length
  }
}

export const  getOffset=(el)=> {
  if(el){
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX+"px",
      top: ((rect.top + window.scrollY)+rect.height)+10+"px"
    };
  }
  
}




export const createOptionsPayload=({allOptions,text})=>{
  const optionsObject = allOptions.reduce((acc, category) => {
    category.options.forEach(option => {
      acc[option.field] = option.checked;
    });
    return acc;
  }, {});
  
  const resultObject = {
    text: text,
    options: optionsObject,
  };
  return resultObject
}

export const gaPurchase = ({ orderID, price, title }) => {
  window.gtag("event", "purchase",{
      transaction_id: orderID,
      affiliation: "AIundetectable.com",
      value: price,
      currency: "USD",
      tax: 0,
      shipping: 0,
      items: [{
        item_id: title,
        item_name: title,
        price: price,
        quantity: 1
      }]
  });
}