import { createSlice } from "@reduxjs/toolkit";



export const getUserSession = createSlice({
  name: 'getUser',
  initialState: '',
  reducers: {
    getUser: (state, action) => {
      state = action.payload;
      return state
    },
    refresh: (state, action) => {
      state = action.payload;
      return state
    }
  },

})

export const { getUser } = getUserSession.actions;

export default getUserSession.reducer;

